<template>
    <div class="bg-white font-poppins">
        <div class="pt-6">
            <nav aria-label="Breadcrumb">
                <ol role="list" class="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">
                    <li v-for="breadcrumb in product.breadcrumbs" :key="breadcrumb.id">
                        <div class="flex items-center">
                            <a :href="breadcrumb.href" class="mr-2 text-sm font-medium text-gray-900">
                                {{ breadcrumb.name }}
                            </a>
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-5 text-gray-300">
                                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                            </svg>
                        </div>
                    </li>
                    <li class="text-sm">
                        <a :href="product.href" aria-current="page" class="text-3xl font-light text-black font-poppins">
                            {{ product.name }}
                        </a>
                    </li>
                </ol>
            </nav>

            <!-- Image gallery -->
            <div class="flex-row lg:flex justify-between mt-6 max-w-2xl mx-auto sm:p-6 lg:max-w-7xl lg:p-8">
              <div class="grow w-full items-center sm:p-6 lg:p-8">
                <div class="w-full aspect-w-3 aspect-h-4 rounded-lg overflow-hidden lg:block">
                  <div class="flex justify-between">
                    <button @click="previous_image()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                    <img :src="image" :alt="product.name" class="w-3/4 h-full rounded-lg object-center object-cover" />
                    <button @click="next_image()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <!-- Options -->
              <div class="mt-4 lg:mt-0 lg:row-span-3 p-6 lg:border-l lg:border-gray-200">
                <h2 class="sr-only">Product information</h2>
                <p class="text-3xl text-gray-900">{{ product.price }} €</p>

                <form class="mt-10">
                  <!-- Colors -->
                  <div>
                    <h3 class="text-sm text-gray-900 font-medium">Color</h3>

                    <RadioGroup v-model="selectedColor" class="mt-4">
                      <RadioGroupLabel class="sr-only">
                        Choose a color
                      </RadioGroupLabel>
                      <div class="flex items-center space-x-3">
                        <RadioGroupOption as="template" v-for="color in product.specs" :key="color.name" :value="color" v-slot="{ active, checked }">
                          <div :class="[color.selectedClass, active && checked ? 'ring ring-offset-1' : '', !active && checked ? 'ring-2' : '', '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none']">
                            <RadioGroupLabel as="p" class="sr-only">
                              {{ color.name }}
                            </RadioGroupLabel>
                            <span aria-hidden="true" :class="[color.class_name, 'h-8 w-8 border border-black border-opacity-10 rounded-full']" />
                          </div>
                        </RadioGroupOption>
                      </div>
                    </RadioGroup>
                  </div>

                  <!-- Sizes -->
                  <div class="mt-10">

                    <RadioGroup v-model="selectedSize" class="mt-4">
                      <RadioGroupLabel class="sr-only">
                        Choose a size
                      </RadioGroupLabel>
                      <div class="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                        <RadioGroupOption as="template" v-for="size in selectedColor.sizes" :key="size.size" :value="size" :disabled="!size.stock" v-slot="{ active, checked }">
                          <div :class="[size.stock ? 'bg-white shadow-sm text-gray-900 cursor-pointer' : 'bg-gray-50 text-gray-200 cursor-not-allowed', active ? 'ring-2 ring-indigo-500' : '', 'group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6']">
                            <RadioGroupLabel as="p">
                              {{ size.size }}
                            </RadioGroupLabel>
                            <div v-if="size.stock" :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-md pointer-events-none']" aria-hidden="true" />
                            <div v-else aria-hidden="true" class="absolute -inset-px rounded-md border-2 border-gray-200 pointer-events-none">
                              <svg class="absolute inset-0 w-full h-full text-gray-200 stroke-2" viewBox="0 0 100 100" preserveAspectRatio="none" stroke="currentColor">
                                <line x1="0" y1="100" x2="100" y2="0" vector-effect="non-scaling-stroke" />
                              </svg>
                            </div>
                          </div>
                        </RadioGroupOption>
                      </div>
                    </RadioGroup>
                  </div>

                  <div class="mt-10 flex flex-wrap">
                    <input type="number" min="1" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-l-md w-1/2" v-model="quantity"/>
                    <a @click="addToCart" class="w-1/2 bg-gray-600 border border-transparent rounded-r-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-coral focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                    </a>
                  </div>

                </form>
              </div>
            </div>

            <!-- Product info -->
            <div class="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
                <div class="lg:col-span-2 lg:pr-8">
                    <h1 class="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl font-poppins">
                        {{ product.name }}
                    </h1>
                </div>

                <div class="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:pr-8">
                    <!-- Description and details -->
                    <div>
                        <h3 class="sr-only">Description</h3>

                        <div class="space-y-6">
                            <p class="text-base text-gray-900">{{ product.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { StarIcon } from '@heroicons/vue/solid'
    import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
    import axios from 'axios'
    import { createToast } from 'mosha-vue-toastify';
    import 'mosha-vue-toastify/dist/style.css'

    const reviews = { href: '#', average: 4, totalCount: 117 }

    export default {
        components: {
            RadioGroup,
            RadioGroupLabel,
            RadioGroupOption,
            StarIcon,
        },
        data() {
            return {
                product: {},
                selectedSize: undefined,
                reviews: reviews,
                selectedColor: '',
                quantity: 1,
                image: '',
                image_1: '',
                image_2: '',
                image_3: '',
                image_4: '',
            }
        },
        async mounted() {
          this.$store.commit('setIsLoading', true)
            await this.getProduct()
            this.selectedColor = this.product.specs[0]
          this.image = this.product.get_image[0]
          this.$store.commit('setIsLoading', false)
        },
        methods: {
          next_image() {
            // Find image index
            let images = this.product.get_image
            let index = images.indexOf(this.image)
            let len = this.product.get_image.length - 1
            if (index === len) {
              this.image = this.product.get_image[0]
            } else if (index < len && index >= 0) {
              this.image = this.product.get_image[index + 1]
            } else {
              this.image = this.product.get_image[0]
            }
          },
          previous_image() {
            // Find image index
            let images = this.product.get_image
            let index = images.indexOf(this.image)
            let len = this.product.get_image.length - 1
            if (index === 0) {
              this.image = this.product.get_image[len]
            } else if (index <= len && index > 0) {
              this.image = this.product.get_image[index - 1]
            } else {
              this.image = this.product.get_image[0]
            }
          },
            async getProduct() {
                this.$store.commit('setIsLoading', true)

                const category_slug = this.$route.params.category_slug
                const product_slug = this.$route.params.product_slug

                await axios
                .get(`/api/v1/product/${category_slug}/${product_slug}`)
                .then(response => {
                    this.product = response.data

                    document.title = this.product.name + ' | Durban'
                })
                .catch(error => {
                    console.log(error)
                })

                this.$store.commit('setIsLoading', false)
            },
            addToCart() {
                if (isNaN(this.quantity) || this.quantity < 1) {
                    this.quantity=1
                }

                if (this.selectedSize === undefined) {
                  createToast('Please, choose a size',
                      {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'bounce'
                      })
                } else if (this.selectedSize.size.stock === 0) {
                  createToast('This product is out of stock',
                      {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'bounce'
                      })
                } else {
                  const item = {
                    product: this.product,
                    size: this.selectedSize,
                    specs: this.selectedColor,
                    quantity: this.quantity
                  }
                  console.log(item)

                  this.$store.commit('addToCart', item)

                  createToast(this.product.name + " added",
                      {
                        position: 'top-right',
                        type: 'success',
                        transition: 'bounce'
                      })
                }
            }
        }
    }
</script>
